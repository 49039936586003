function addBusinessDays(date, add){
    for (let i = 1; i <= add; i++) {
        date.setDate(date.getDate() + 1);
        if (date.getDay() === 6) {
            date.setDate(date.getDate() + 2);
        }
        else if (date.getDay() === 0) {
            date.setDate(date.getDate() + 1);
        }
    }

    return date;
}

// Date format functions
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
    
// Backup of Old Format Date Function
// function formatDate(date) {
//     return [
//         padTo2Digits(date.getMonth() + 1),
//         padTo2Digits(date.getDate()),
//         date.getFullYear(),
//     ].join('/');
// }

function formatSimpleDate(date){
    var newDate = new Date(date);
    //newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return [
        padTo2Digits(newDate.getMonth() + 1),
        padTo2Digits(newDate.getDate()),
        newDate.getFullYear(),
    ].join('/');
}

function formatDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return [
        padTo2Digits(newDate.getMonth() + 1),
        padTo2Digits(newDate.getDate()),
        newDate.getFullYear(),
    ].join('/');
}

module.exports = {addBusinessDays, formatSimpleDate, formatDate }

