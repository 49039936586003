import ReactDOM from "react-dom/client";
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from "./Pages/Home"
import NoPage from "./Pages/NoPage"
import './index.scss'
//import { UserContext } from "./context/UserContext";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

// Bootstrap components
//import 'bootstrap/dist/css/bootstrap.min.css';

const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  //const [ user, setUser ] = useState(null)
  
  return (
    <BrowserRouter>
      {/* <UserContext.Provider value={{user, setUser}}> */}
      <MsalProvider instance={msalInstance}>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            {/* Catch All */}
            <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </MsalProvider>
      {/* </UserContext.Provider> */}
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// if (window.location.hash !== ''){
//   console.log("hash found" + window.location.hash);
// }
// else {
  root.render(<App />);
//}



