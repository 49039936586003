import React from 'react'
import { Link } from 'react-router-dom'
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "../Components/SignOutButton"



export default function Header() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <header>
        <div className="header-content">
            <div className="flex-item">
              <Link to="/" title="Home">
                <img className="header-logo" src="assets/images/main_logo2.jpg" alt="Company Logo" align="absmiddle" />
              </Link>
            </div>

            <div className="flex-item header-title center">
              Application Functionality Tracking
            </div>

            <div className="flex-item">
              {isAuthenticated && <SignOutButton/>}
            </div>
        </div>
    </header>
  )
}
