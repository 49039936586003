import React, { useState, useEffect, useRef } from 'react'
import { formatSimpleDate } from '../Utils/utils'
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../Components/SignInButton"

export default function Home() {
    const [availableTickets, setAvailableTickets] = useState();
    const [pendingTickets, setPendingTickets] = useState();
    const [reviewTickets, setReviewTickets] = useState();
    const [otherTickets, setOtherTickets] = useState();
    const [rejectedTickets, setRejectedTickets] = useState();
    const [firstLoad, setFirstLoad] = useState(true);

    const [availableOriginal, setAvailableOriginal] = useState();
    const [pendingOriginal, setPendingOriginal] = useState();
    const [reviewOriginal, setReviewOriginal] = useState();
    const [otherOriginal, setOtherOriginal] = useState();
    const [rejectedOriginal, setRejectedOriginal] = useState();

    const [availableSorting, setAvailableSorting] = useState({key:'released', direction:'descending'});
    const [pendingSorting, setPendingSorting] = useState({key:'duedate', direction:'descending'});
    const [reviewSorting, setReviewSorting] = useState({key:'duedate', direction:'descending'});
    const [otherSorting, setOtherSorting] = useState({key:'duedate', direction:'descending'});
    const [rejectedSorting, setRejectedSorting] = useState({key:'updated', direction:'descending'});

    const stickyRow = useRef();
    const refReturnTop = useRef();
    const refSearch = useRef();
    const refClearSearch = useRef();
    const [ search, setSearch ] = useState('')
        
    const availableTable = useRef();
    const pendingTable = useRef();
    const reviewTable = useRef();
    const otherTable = useRef();
    const rejectedTable = useRef();
    
    // Sort Icon References
    const ref_available_id = useRef();
    const ref_available_title = useRef();
    const ref_available_released = useRef();
    const ref_available_use = useRef();
    const ref_available_status = useRef();

    const ref_pending_id = useRef();
    const ref_pending_title = useRef();
    const ref_pending_updated = useRef();
    const ref_pending_duedate = useRef();
    const ref_pending_use = useRef();
    const ref_pending_status = useRef();
    
    const ref_review_id = useRef();
    const ref_review_title = useRef();
    const ref_review_updated = useRef();
    const ref_review_duedate = useRef();
    const ref_review_use = useRef();
    const ref_review_status = useRef();
    
    const ref_other_id = useRef();
    const ref_other_title = useRef();
    const ref_other_updated = useRef();
    const ref_other_duedate = useRef();
    const ref_other_use = useRef();
    const ref_other_status = useRef();

    const ref_rejected_id = useRef();
    const ref_rejected_title = useRef();
    const ref_rejected_updated = useRef();
    const ref_rejected_use = useRef();
    const ref_rejected_status = useRef();


    //Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();

    const isAuthenticated = useIsAuthenticated();
    //const isAuthenticated = true;
    
    useEffect(() => {
        const loadJira = async () => {
            showLoading();
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
            };

            let response = await fetch('https://functionality.lahlouhonline.com/api/jira-tickets/', requestOptions);
            //let response = await fetch('http://localhost:8080/api/jira-tickets/', requestOptions);
            let data = await response.json()

            if(data.length > 0){
                let newObj = [];
                let objData;
                for(let a=0;a<data.length;a++){
                    objData = {
                        id: data[a].key,
                        title: data[a].fields.summary,
                        description: data[a].fields.description,
                        created: data[a].fields.created,
                        updated: data[a].fields.updated,
                        duedate: data[a].fields.duedate,
                        date_closed: data[a].date_closed,
                        date_cancelled: data[a].date_cancelled,
                        issuetype: data[a].fields.issuetype.name,
                        status: data[a].fields.status.name,
                        released: data[a].fields.customfield_10060 !== '' ? data[a].fields.customfield_10060 : '',
                        use: data[a].fields.customfield_10061 !== null ? data[a].fields.customfield_10061.value : ''
                    }
                    newObj.push(objData);
                }
                let results;
                // Create Available Results
                results = [...newObj]
                  .filter(ticket => (ticket.status.toLowerCase() === 'closed' || ticket.status.toLowerCase() === 'done') && ticket.issuetype !== 'Bug' && ticket.issuetype !== 'Task' && ticket.status.toLowerCase() !== 'cancelled')
                  .sort((a,b) => {
                    if(availableSorting.key === 'released'){
                        a = a[availableSorting.key];
                        b = b[availableSorting.key];
                    }else{
                        a = a[availableSorting.key].toLowerCase()
                        b = b[availableSorting.key].toLowerCase()
                    }
                    if(a == b) return 0
                    if (a < b) return availableSorting.direction === 'ascending' ? -1 : 1;
                    if (a > b) return availableSorting.direction === 'ascending' ? 1 : -1;
                })
                setAvailableTickets(results);
                setAvailableOriginal(results);

                // Create Pending Dev Results
                results = [...newObj]
                  .filter(ticket => (ticket.status.toLowerCase() === 'ready for dev' || ticket.status.toLowerCase().indexOf('in development') !== -1 || ticket.status.toLowerCase() === 'ready for qa' || ticket.status.toLowerCase() === 'in qa') && ticket.issuetype !== 'Bug' && ticket.issuetype !== 'Task' && ticket.status.toLowerCase() !== 'cancelled')
                  .sort((a,b) => {
                    if(pendingSorting.key === 'updated' || pendingSorting.key === 'duedate'){
                        a = a[pendingSorting.key];
                        b = b[pendingSorting.key];
                    }else{
                        a = a[pendingSorting.key].toLowerCase()
                        b = b[pendingSorting.key].toLowerCase()
                    }
                    if(a == b) return 0
                    if (a < b) return pendingSorting.direction === 'ascending' ? -1 : 1;
                    if (a > b) return pendingSorting.direction === 'ascending' ? 1 : -1;
                })

                setPendingTickets(results);
                setPendingOriginal(results);

                // Create Pending Review Results
                results = [...newObj]
                  .filter(ticket => (ticket.status.toLowerCase() === 'backlog' || ticket.status.toLowerCase() === 'blocked') && ticket.issuetype !== 'Bug' && ticket.issuetype !== 'Task' && ticket.status.toLowerCase() !== 'cancelled')
                  .sort((a,b) => {
                    if(reviewSorting.key === 'updated' || reviewSorting.key === 'duedate'){
                        a = a[reviewSorting.key];
                        b = b[reviewSorting.key];
                    }else{
                        a = a[reviewSorting.key].toLowerCase()
                        b = b[reviewSorting.key].toLowerCase()
                    }
                    if(a == b) return 0
                    if (a < b) return reviewSorting.direction === 'ascending' ? -1 : 1;
                    if (a > b) return reviewSorting.direction === 'ascending' ? 1 : -1;
                })
                setReviewTickets(results);
                setReviewOriginal(results);

                // Create Other Results
                results = [...newObj]
                  .filter(ticket => (ticket.issuetype === 'Bug' || ticket.issuetype === 'Task') && ticket.status.toLowerCase() !== 'cancelled')
                  .sort((a,b) => {
                    if(otherSorting.key === 'updated' || otherSorting.key === 'duedate'){
                        a = a[otherSorting.key];
                        b = b[otherSorting.key];
                    }else{
                        a = a[otherSorting.key].toLowerCase()
                        b = b[otherSorting.key].toLowerCase()
                    }
                    if(a == b) return 0
                    if (a < b) return otherSorting.direction === 'ascending' ? -1 : 1;
                    if (a > b) return otherSorting.direction === 'ascending' ? 1 : -1;
                })
                setOtherTickets(results);
                setOtherOriginal(results);

                // Create Rejected Results
                results = [...newObj]
                .filter(ticket => ticket.status.toLowerCase() === 'cancelled')
                  .sort((a,b) => {
                    if(rejectedSorting.key === 'updated' || rejectedSorting.key === 'duedate'){
                        a = a[rejectedSorting.key];
                        b = b[rejectedSorting.key];
                    }else{
                        a = a[rejectedSorting.key].toLowerCase()
                        b = b[rejectedSorting.key].toLowerCase()
                    }
                    if(a == b) return 0
                    if (a < b) return rejectedSorting.direction === 'ascending' ? -1 : 1;
                    if (a > b) return rejectedSorting.direction === 'ascending' ? 1 : -1;
                })
                setRejectedTickets(results);
                setRejectedOriginal(results);

                setSearch('');
                hideLoading();
            }
        }

        if(isAuthenticated) loadJira();
    }, [isAuthenticated])
   
    async function clearSearch(){
        setSearch('');
        refSearch.current.style.width = '225px';
        refClearSearch.current.style.display = 'none';
        setAvailableTickets([...availableOriginal]);
        setPendingTickets([...pendingOriginal]);
        setReviewTickets([...reviewOriginal]);
        setOtherTickets([...otherOriginal]);
        setRejectedTickets([...rejectedOriginal]);

        // Set to original column sorting
    }

    // Search functionality
    async function handleChange(e){
        e.preventDefault();
        // Adjust width of search box with CSS transition
        if(e.target.value !== ''){
            refSearch.current.style.width = '250px';
            refClearSearch.current.style.display = 'inline';
        }else{
            refSearch.current.style.width = '225px';
            refClearSearch.current.style.display = 'none';
        }

        // Filter result set in real-time
        if(e.target.value === ''){
            setAvailableTickets([...availableOriginal]);
            setPendingTickets([...pendingOriginal]);
            setReviewTickets([...reviewOriginal]);
            setOtherTickets([...otherOriginal]);
            setRejectedTickets([...rejectedOriginal]);
        }else{
            let results;
            results = availableOriginal.filter(item => item.id.toLowerCase().indexOf(e.target.value) !== -1 || item.title.toLowerCase().indexOf(e.target.value) !== -1 || (item.description !== null && item.description.toLowerCase().indexOf(e.target.value) !== -1))
            setAvailableTickets(results);
            results = pendingOriginal.filter(item => item.id.toLowerCase().indexOf(e.target.value) !== -1 || item.title.toLowerCase().indexOf(e.target.value) !== -1 || (item.description !== null && item.description.toLowerCase().indexOf(e.target.value) !== -1))
            setPendingTickets(results);
            results = reviewOriginal.filter(item => item.id.toLowerCase().indexOf(e.target.value) !== -1 || item.title.toLowerCase().indexOf(e.target.value) !== -1 || (item.description !== null && item.description.toLowerCase().indexOf(e.target.value) !== -1))
            setReviewTickets(results);
            results = otherOriginal.filter(item => item.id.toLowerCase().indexOf(e.target.value) !== -1 || item.title.toLowerCase().indexOf(e.target.value) !== -1 || (item.description !== null && item.description.toLowerCase().indexOf(e.target.value) !== -1))
            setOtherTickets(results);
            results = rejectedOriginal.filter(item => item.id.toLowerCase().indexOf(e.target.value) !== -1 || item.title.toLowerCase().indexOf(e.target.value) !== -1 || (item.description !== null && item.description.toLowerCase().indexOf(e.target.value) !== -1))
            setRejectedTickets(results);
        }

        setSearch(e.target.value)
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function rowClick(id){
        //window.location = "https://lahlouh.atlassian.net/browse/" + id;
        window.open('https://lahlouh.atlassian.net/browse/' + id,'_blank', 'rel=noopener noreferrer')

    }

    function showLoading(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        loadingOverlay.current.className = 'show';
    }
  
    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(loadingOverlay.current) loadingOverlay.current.className = '';
    }

    async function expandResults(e){
        let currentClass = e.currentTarget.firstChild.children[0].className
        let dataName = e.currentTarget.getAttribute('data-name')
        let rows;
        
        if(dataName === 'available') rows = availableTable.current.rows
        if(dataName === 'pending') rows = pendingTable.current.rows
        if(dataName === 'review') rows = reviewTable.current.rows
        if(dataName === 'other') rows = otherTable.current.rows
        if(dataName === 'rejected') rows = rejectedTable.current.rows
            
        if(rows.length > 2){
            for(let a=2;a<rows.length;a++){
                if(currentClass === 'expand-down'){        
                    rows[a].className = 'slave-highlight active'
                }else{
                    rows[a].className = 'slave-highlight'
                }
            }

            if(currentClass === 'expand-up'){
                e.currentTarget.firstChild.children[0].className = 'expand-down'
                e.currentTarget.title = "Click to expand results"
            }else{
                e.currentTarget.firstChild.children[0].className = 'expand-up'
                e.currentTarget.title = "Click to collapse results"
            }
        }
    }

    const sortAvailable = async (key, reset=false) => {
        let direction;
        if(eval('ref_available_' + key).current.className === 'arrow-up'){
            direction = 'ascending';
        }else if(eval('ref_available_' + key).current.className === 'arrow-down'){
            direction = 'descending';
        }else{
            direction = null;
        }

        // Column not currently sorted
        // Set to descending by default
        if(!direction){
            // Remove all table sorting arrows
            ref_available_id.current.className = '';
            ref_available_title.current.className = '';
            ref_available_released.current.className = '';
            ref_available_status.current.className = '';
            eval('ref_available_' + key + '.current.className="arrow-down"')
            direction = 'descending';
        }else{
            if(!reset){
                if(direction === 'descending'){
                    eval('ref_available_' + key + '.current.className="arrow-up"');
                    direction = 'ascending';
                }else{
                    eval('ref_available_' + key + '.current.className="arrow-down"');
                    direction = 'descending';
                }
            }
        }

        let newTickets = availableTickets.sort((a, b) => {
            if(key === 'released'){
                a = a[key];
                b = b[key];
            }else{
                a = a[key].toLowerCase()
                b = b[key].toLowerCase()
            }
            if(a == b) return 0
            if (a < b) return direction === 'ascending' ? -1 : 1;
            if (a > b) return direction === 'ascending' ? 1 : -1;
        });

        await setAvailableTickets([...newTickets])
        setAvailableOriginal([...newTickets])
        setAvailableSorting({key, direction})
    }

    const sortPending = async (key, reset=false) => {
        let direction;
        if(eval('ref_pending_' + key).current.className === 'arrow-up'){
            direction = 'ascending';
        }else if(eval('ref_pending_' + key).current.className === 'arrow-down'){
            direction = 'descending';
        }else{
            direction = null;
        }

        // Column not currently sorted
        // Set to descending by default
        if(!direction){
            // Remove all table sorting arrows
            ref_pending_id.current.className = '';
            ref_pending_title.current.className = '';
            ref_pending_updated.current.className = '';
            ref_pending_duedate.current.className = '';
            ref_pending_status.current.className = '';
            eval('ref_pending_' + key + '.current.className="arrow-down"')
            direction = 'descending';
        }else{
            if(direction === 'descending'){
                eval('ref_pending_' + key + '.current.className="arrow-up"');
                if(!reset) direction = 'ascending';
            }else{
                eval('ref_pending_' + key + '.current.className="arrow-down"');
                if(!reset) direction = 'descending';
            }
        }

        let newTickets = pendingTickets.sort((a, b) => {
            if(key === 'updated' || key === 'duedate'){
                a = a[key];
                b = b[key];
            }else{
                a = a[key].toLowerCase()
                b = b[key].toLowerCase()
            }
            if(a == b) return 0
            if (a < b) return direction === 'ascending' ? -1 : 1;
            if (a > b) return direction === 'ascending' ? 1 : -1;
        });

        await setPendingTickets([...newTickets])
        setPendingOriginal([...newTickets])
        setPendingSorting({key, direction})
    }

    const sortReview = async (key, reset=false) => {
        let direction;
        if(eval('ref_review_' + key).current.className === 'arrow-up'){
            direction = 'ascending';
        }else if(eval('ref_review_' + key).current.className === 'arrow-down'){
            direction = 'descending';
        }else{
            direction = null;
        }

        // Column not currently sorted
        // Set to descending by default
        if(!direction){
            // Remove all table sorting arrows
            ref_review_id.current.className = '';
            ref_review_title.current.className = '';
            ref_review_updated.current.className = '';
            ref_review_duedate.current.className = '';
            ref_review_status.current.className = '';
            eval('ref_review_' + key + '.current.className="arrow-down"')
            direction = 'descending';
        }else{
            if(direction === 'descending'){
                eval('ref_review_' + key + '.current.className="arrow-up"');
                if(!reset) direction = 'ascending';
            }else{
                eval('ref_review_' + key + '.current.className="arrow-down"');
                if(!reset) direction = 'descending';
            }
        }

        let newTickets = reviewTickets.sort((a, b) => {
            if(key === 'updated' || key === 'duedate'){
                a = a[key];
                b = b[key];
            }else{
                a = a[key].toLowerCase()
                b = b[key].toLowerCase()
            }
            if(a == b) return 0
            if (a < b) return direction === 'ascending' ? -1 : 1;
            if (a > b) return direction === 'ascending' ? 1 : -1;
        });

        await setReviewTickets([...newTickets])
        setReviewOriginal([...newTickets])
        setReviewSorting({key, direction})
    }

    const sortOther = async (key, reset=false) => {
        let direction;
        if(eval('ref_other_' + key).current.className === 'arrow-up'){
            direction = 'ascending';
        }else if(eval('ref_other_' + key).current.className === 'arrow-down'){
            direction = 'descending';
        }else{
            direction = null;
        }

        // Column not currently sorted
        // Set to descending by default
        if(!direction){
            // Remove all table sorting arrows
            ref_other_id.current.className = '';
            ref_other_title.current.className = '';
            ref_other_updated.current.className = '';
            ref_other_duedate.current.className = '';
            ref_other_status.current.className = '';
            eval('ref_other_' + key + '.current.className="arrow-down"')
            direction = 'descending';
        }else{
            if(direction === 'descending'){
                eval('ref_other_' + key + '.current.className="arrow-up"');
                if(!reset) direction = 'ascending';
            }else{
                eval('ref_other_' + key + '.current.className="arrow-down"');
                if(!reset) direction = 'descending';
            }
        }

        let newTickets = otherTickets.sort((a, b) => {
            if(key === 'updated' || key === 'duedate'){
                a = a[key];
                b = b[key];
            }else{
                a = a[key].toLowerCase()
                b = b[key].toLowerCase()
            }
            if(a == b) return 0
            if (a < b) return direction === 'ascending' ? -1 : 1;
            if (a > b) return direction === 'ascending' ? 1 : -1;
        });

        await setOtherTickets([...newTickets])
        setOtherOriginal([...newTickets])
        setOtherSorting({key, direction})
    }

    const sortRejected = async (key, reset=false) => {
        let direction;
        if(eval('ref_rejected_' + key).current.className === 'arrow-up'){
            direction = 'ascending';
        }else if(eval('ref_rejected_' + key).current.className === 'arrow-down'){
            direction = 'descending';
        }else{
            direction = null;
        }

        // Column not currently sorted
        // Set to descending by default
        if(!direction){
            // Remove all table sorting arrows
            ref_rejected_id.current.className = '';
            ref_rejected_title.current.className = '';
            ref_rejected_updated.current.className = '';
            ref_rejected_status.current.className = '';
            eval('ref_rejected_' + key + '.current.className="arrow-down"')
            direction = 'descending';
        }else{
            if(direction === 'descending'){
                eval('ref_rejected_' + key + '.current.className="arrow-up"');
                direction = 'ascending';
            }else{
                eval('ref_rejected_' + key + '.current.className="arrow-down"');
                direction = 'descending';
            }
        }

        let newTickets = rejectedTickets.sort((a, b) => {
            if(key === 'updated' || key === 'duedate'){
                a = a[key];
                b = b[key];
            }else{
                a = a[key].toLowerCase()
                b = b[key].toLowerCase()
            }
            if(a == b) return 0
            if (a < b) return direction === 'ascending' ? -1 : 1;
            if (a > b) return direction === 'ascending' ? 1 : -1;
        });

        await setRejectedTickets([...newTickets])
        setRejectedOriginal([...newTickets])
        setRejectedSorting({key, direction})
    }

    // Create Release Notes for Email
    async function releaseNotesEmail(){
        let html = '';
        let desc;
        let start, end, sliced, split, replacement;
        
        for(let a=0;a<availableTickets.length;a++){
            html += "<div style='padding:25px 0;border-bottom:2px solid #CCC;'>";
            html += "<div style='font-size:16px;font-weight:bold;'>" + availableTickets[a].title + "</div>";
            html += "<div style='margin-top:8px;'><strong>Release Date: </strong>" + availableTickets[a].released + "</div>";
            html += "<div style='margin-top:8px;'><strong>Use: </strong>" + availableTickets[a].use + "</div>";
            html += "<div style='margin-top:8px;'><strong>Jira: </strong><a href='https://lahlouh.atlassian.net/browse/" + availableTickets[a].id + "' target='_blank'>" + availableTickets[a].id + "</a></div>";
            html += "<div style='margin-top:8px;'><strong>Notes:</strong></div>";
            html += "<div style='line-height:22px;margin-top:8px;'>";
            
            desc = availableTickets[a].description;
            
            // Add Line Breaks
            desc = desc.replace(/\n/g, "<p>");

            
            // Remove custom styling (color mostly)
            for(let i=0;i<desc.length;i++){
                start = desc.indexOf('{')
                end = desc.indexOf('}')
                if(start === -1) break;
                sliced = desc.slice(start, end+1)
                desc = desc.replace(sliced,'')
            }
            
            // Remove All Bold
            // * represents bold and bullets
            desc = desc.replace(/\*/g, "");
            
            // Bold / Bullets - Turned off for now
            let count = 1;
            for(let i=0;i<desc.length;i++){
                let z = desc.indexOf('*')
                if(z === -1) break;
                if(count%2 == 0){
                    //desc = desc.replace('*', '</b>');
                }else{
                    //desc = desc.replace('*', '<b>');
                }
                count ++;
            }

            // Hyperlinks
            start = desc.indexOf('[');
            while(start !== -1){
                end = desc.indexOf(']', start+1)
                sliced = desc.slice(start, end + 1);
                split = sliced.split('|')
                
                if(split.length > 1){
                    desc = desc.replace(sliced, "<a href='" + split[1].replace('[', '') + "' target='_blank'>" + split[0].replace('[', '') + "</a>");
                }
                start = desc.indexOf("[", start+1);
            }

            // Images
            start = desc.indexOf("!image");
            while (start !== -1) {
                end = desc.indexOf("!", start + 1);
                sliced = desc.slice(start, end+1)
                desc = desc.replace(sliced,'-Image Placeholder-')
                start = desc.indexOf("!image", start + 1);
            }
           
            // Underlines
            count = 0;
            start = desc.indexOf("+");
            while (start !== -1) {
                count ++;
                end = desc.indexOf("+", start + 1);
                //sliced = desc.slice(start, end+1)
                if(count%2==0){
                  desc = desc.replace('+', '</u>')  
                }else{
                    desc = desc.replace('+', '<u>')  
                }
                start = desc.indexOf("+", start + 1);
            }

            // Horizontal Rule
            // start = desc.indexOf("----");
            // while (start !== -1) {
            //     end = desc.indexOf("!", start + 1);
            //     sliced = desc.slice(start, end+1)
            //     desc = desc.replace(sliced,'-Image Placeholder-')
            //     start = desc.indexOf("!image", start + 1);
            // }

            let pos = desc.indexOf('----');
            
            if(pos !== -1){
                desc = desc.replace(desc.substring(pos-3,pos+7), '<div style="border-bottom:1px solid #d3d3d3;"></div>')
            }

            html += desc;
            html += '</div></div>';
        }

        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({html})
        };

        //document.getElementById('release-notes').innerHTML = html;
        let response = await fetch('http://localhost:8080/api/email', requestOptions);
        //let data = await response.json()
    }

    async function releaseNotes(){
        let html = '';
        let desc;
        let start, end, sliced, split;

        for(let a=0;a<availableTickets.length;a++){
            html += '<div class="release-note">';
            html += '<div class="rn-title">' + availableTickets[a].title + '</div>';
            html += '<div class="rn-released mt8"><strong>Release Date: </strong>' + availableTickets[a].released + '</div>';
            html += '<div class="rn-use mt8"><strong>Use: </strong>' + availableTickets[a].use + '</div>';
            html += '<div class="rn-jira mt8"><strong>Jira: </strong><a href="https://lahlouh.atlassian.net/browse/' + availableTickets[a].id + '" target="_blank">' + availableTickets[a].id + '</a></div>';
            html += '<div class="mt8"><strong>Notes:</strong></div>';
            html += '<div class="rn-desc mt8">';
            
            desc = availableTickets[a].description;
            
            // Add Line Breaks
            desc = desc.replace(/\n/g, "<p>");

            
            // Remove custom styling (color mostly)
            for(let i=0;i<desc.length;i++){
                start = desc.indexOf('{')
                end = desc.indexOf('}')
                if(start === -1) break;
                sliced = desc.slice(start, end+1)
                desc = desc.replace(sliced,'')
            }
            
            // Rebuild anchor links
            for(let i=0;i<desc.length;i++){
                start = desc.indexOf('[')
                end = desc.indexOf(']')
                if(start === -1) break;

                sliced = desc.slice(start, end+1)
                split = sliced.split('|')

                if(split.length > 1){
                    desc = desc.replace(sliced, '<a href="' + split[1] + '" target="_blank">' + split[0] + '</a>').replace('[','').replace(']', '');
                }
            }

            // Remove All Bold
            desc = desc.replace(/\*/g, "");
            
            // Bold - Turned off for now - Same as bullets
            let count = 1;
            for(let i=0;i<desc.length;i++){
                let z = desc.indexOf('*')
                if(z === -1) break;
                if(count%2 == 0){
                    //desc = desc.replace('*', '</b>');
                }else{
                    //desc = desc.replace('*', '<b>');
                }
                count ++;
            }

            // Images
            start = desc.indexOf("!image");
            while (start !== -1) {
                end = desc.indexOf("!", start + 1);
                sliced = desc.slice(start, end+1)
                desc = desc.replace(sliced,'-Image Placeholder-')
                start = desc.indexOf("!image", start + 1);
            }
           
            // Underlines
            count = 0;
            start = desc.indexOf("+");
            while (start !== -1) {
                count ++;
                end = desc.indexOf("+", start + 1);
                //sliced = desc.slice(start, end+1)
                if(count%2==0){
                  desc = desc.replace('+', '</u>')  
                }else{
                    desc = desc.replace('+', '<u>')  
                }
                start = desc.indexOf("+", start + 1);
            }

            html += desc;
            html += '</div></div>';
        }

        
        
        document.getElementById('release-notes').innerHTML = html;
        
    }
    
    function formatDescription(str, page=false){
        // Remove Custom Styling ({ })
        let a, b, sliced, split;
        for(let i=0;i<str.length;i++){
            a = str.indexOf('{')
            b = str.indexOf('}')
            if(a === -1) break;
            sliced = str.slice(a, b+1)
            str = str.replace(sliced,'')
        }

        // Remove Links ([ ])
        if(!page){
            for(let i=0;i<str.length;i++){
                a = str.indexOf('[')
                b = str.indexOf(']')
                if(a === -1) break;
                sliced = str.slice(a, b+1)
                str = str.replace(sliced,'<Link>')
            }
        }

        // Rebuild links if release notes
        if(page){
            for(let i=0;i<str.length;i++){
                a = str.indexOf('[')
                b = str.indexOf(']')
                if(a === -1) break;
                sliced = str.slice(a, b+1)
                
                split = sliced.split('|')//.filter(item => item.indexOf('http') !== -1)
                if(split.length > 1){
                    str = str.replace(sliced, '<a href="' + split[1] + '" target="_blank">' + split[0] + '</a>').replace('[','').replace(']', '');
                }
                
                //str = str.replace(sliced,'<Link>')
            }

        }

        // Remove / Replace Bold (*)
        if(!page){
            str = str.replace(/\*/g, "");
        }else{
            let count = 1;
            for(let i=0;i<str.length;i++){
                a = str.indexOf('*')
                if(a === -1) break;
                if(count%2 === 0){
                    str = str.replace('*', '</strong>');
                }else{
                    str = str.replace('*', '<strong>');
                }
                count ++;
            }
        }
    

        // Remove Underline
        str = str.replace(/\+/g, "");

        // Cut Length Down
        if(!page){
            str = str.substring(0,185);
            if(str.length > 184) str += "...";
        }
        
        if(str.indexOf('<a ') !== -1){
            document.getElementById('desc-IT-4802').innerHTML = str;
        }

        return str;
       
    }

    return (
    <>
    
    {!isAuthenticated &&
    <div className="main center mt20">
        You must be signed in to view this page
        <div className="center mt20">
            <SignInButton/>
        </div>
    </div>
    }
    
    {isAuthenticated &&
    <div className="main">
        <div className="main-content">

        {/* Release Notes */}
        <div id="release-notes" className="release-notes"></div>
           
        <div className="center">
            This page shows all of the Jira requests that are related to application functionality.
            <div className="mt10">
                <a href="https://www.xmpie.com/ustore-whats-new/" target="_blank" title="View the latest updates to uStore">View the latest updates to uStore</a>
            </div>

            <div className="thin-divider"></div>

            <div className="center">
                <ul className="page-links">
                    <li><a href="#available" title="View available functionality">Available Functionality ({availableTickets && availableTickets.length > 0 ? availableTickets.length : '0'})</a></li>
                    <li><a href="#pending-dev" title="View pending development">Pending Development ({pendingTickets && pendingTickets.length > 0 ? pendingTickets.length : '0'})</a></li>
                    <li><a href="#pending-rev" title="View pending review">Pending Review ({reviewTickets && reviewTickets.length > 0 ? reviewTickets.length : '0'})</a></li>
                    <li><a href="#other" title="View other development">Other Development ({otherTickets && otherTickets.length > 0 ? otherTickets.length : '0'})</a></li>
                    <li><a href="#rejected" title="View cancelled/rejected">Cancelled / Rejected ({rejectedTickets && rejectedTickets.length > 0 ? rejectedTickets.length : '0'})</a></li>
                </ul>
            </div>
        </div>
        
        <div className="search-wrapper mb20">
            <input
                className="requests-search center"
                name="search"
                ref={refSearch}
                type="text"
                placeholder="Search by ID, Title or Desc..."
                value={search}
                onChange={handleChange}
            />
            <span
                className="clear-search"
                ref={refClearSearch}
                onClick={clearSearch}
                title="Clear Search"
            >x</span>
        </div>

        {availableTickets && availableTickets.length > 0 &&
        <>
        <a name="available"></a>
        <div className="flex-wrapper">
            {/* <div><span className={search === '' ? "expand-down" : "expand-up"}></span></div> */}
            <div className="num-results"><strong>{availableTickets && availableTickets.length}</strong> result{availableTickets && availableTickets.length !== 1 && 's' }</div>
            <h2>Available Functionality</h2>
            {/* <div className="right">
                <div className="expand">+</div>
            </div> */}
            <div></div>
        </div>

        <table ref={availableTable} className="tracking-table mb40" cellPadding="0" cellSpacing="0">
            <thead>
                <tr ref={stickyRow}>
                    <th onClick={() => sortAvailable('id')} title="Sort by Jira ID">JIRA <span ref={ref_available_id}></span></th>
                    <th onClick={() => sortAvailable('title')} title="Sort by Title">Title <span ref={ref_available_title}></span></th>
                    <th>Description</th>
                    {/* Due Date - None */}
                    {/* <th>Due Date</th> */}
                    {/* For pending dev. */}
                    {/* <th onClick={() => sortAvailable('updated')} title="Sort by Date Updated">Updated <span ref={ref_available_updated} className="arrow-down"></span></th> */}
                    <th onClick={() => sortAvailable('use')} title="Sort by Use">Use <span ref={ref_available_use}></span></th>
                    <th onClick={() => sortAvailable('released')} title="Sort by Release Date">Released <span ref={ref_available_released} className="arrow-down"></span></th>
                    {/* Labels - None */}
                    {/* <th>Labels</th> */}
                    {/* Priority - All medium */}
                    {/* <th>Priority</th> */}
                    <th onClick={() => sortAvailable('status')} title="Sort by Status">Status <span ref={ref_available_status}></span></th>
                    <th></th>
                </tr>
                
            </thead>
            <tbody>
            <tr data-name="available" title="Click to expand results" onClick={expandResults}>
                <td><span className={search === '' ? "expand-down" : "expand-up"}></span></td>
                <td>View all {availableTickets && availableTickets.length} results</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            
            {availableTickets && availableTickets.map(ticket => {
                let str = ticket.description;
                
                if(str != null){
                    str = formatDescription(str);
                }else{
                    str = "";
                }

                return (
               <tr 
                  key={ticket.id}
                  className={search === '' ? "slave-highlight" : "slave-highlight active"} 
                  data-id={ticket.id}
                  title="Click to view Jira ticket"
                  onClick={() => rowClick(ticket.id)}
                >
                    <td data-label="Jira"><div>{ticket.id}</div></td>
                    <td data-label="Title"><div>{ticket.title.length < 101 ? ticket.title : ticket.title.substring(0,100) + "..."}</div></td>
                    <td data-label="Desc"><div>{str}</div></td>
                    <td data-label="Use"><div>{ticket.use ? ticket.use : ''}</div></td>
                    <td data-label="Released"><div>{ticket.released && ticket.released !== null && ticket.released !== '' && formatSimpleDate(new Date(ticket.released.replace(/-/g, '\/').replace(/T.+/, '')))}</div></td>
                    {/* <td data-label="Released"><div>Hello</div></td> */}
                    <td data-label="Status"><div>{ticket.status}</div></td>
                    {/* Jira link only displays on mobile */}
                    <td><a href={"https://lahlouh.atlassian.net/browse/" + ticket.id} target="_blank">View Jira</a></td>
                </tr>
                )
            })
            }
            </tbody>
        </table>
        </>
        }

        {pendingTickets && pendingTickets.length > 0 &&
        <>
        <a name="pending-dev"></a>
        <div className="flex-wrapper">
            <div className="num-results"><strong>{pendingTickets && pendingTickets.length}</strong> result{pendingTickets && pendingTickets.length !== 1 && 's'}</div>
            <h2>Pending Development</h2>
            <div></div>
        </div>
        
        <table ref={pendingTable} className="pending-table mb40" cellPadding="0" cellSpacing="0">
            <thead>
                <tr ref={stickyRow}>
                    <th onClick={() => sortPending('id')} title="Sort by Jira ID">JIRA <span ref={ref_pending_id}></span></th>
                    <th onClick={() => sortPending('title')} title="Sort by Title">Title <span ref={ref_pending_title}></span></th>
                    <th>Description</th>
                    <th onClick={() => sortPending('use')} title="Sort by Use">Use <span ref={ref_pending_use}></span></th>
                    <th onClick={() => sortPending('updated')} title="Sort by Date Updated">Updated <span ref={ref_pending_updated}></span></th>
                    <th onClick={() => sortPending('duedate')} title="Sort by Due Dat">Due <span ref={ref_pending_duedate} className="arrow-down"></span></th>
                    <th onClick={() => sortPending('status')} title="Sort by Status">Status <span ref={ref_pending_status}></span></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

            <tr data-name="pending" title="Click to expand results" onClick={expandResults}>
                <td><span className={search === '' ? "expand-down" : "expand-up"}></span></td>
                <td>View all {pendingTickets && pendingTickets.length} results</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            {pendingTickets && pendingTickets.map(ticket => {
                let str = ticket.description;
                
                if(str != null){
                    str = formatDescription(str);
                }else{
                    str = "";
                }

                return (

                <tr 
                  key={ticket.id}
                  className={search === '' ? "slave-highlight" : "slave-highlight active"} 
                  data-id={ticket.id}
                  title="Click to view Jira ticket"
                  onClick={() => rowClick(ticket.id)}
                >
                    <td data-label="Jira"><div>{ticket.id}</div></td>
                    <td data-label="Title"><div>{ticket.title.length < 101 ? ticket.title : ticket.title.substring(0,100) + "..."}</div></td>
                    <td data-label="Desc"><div>{str}</div></td>
                    {/* <td>{ticket.fields.duedate}</td> */}
                    <td data-label="Use"><div>{ticket.use ? ticket.use : ''}</div></td>
                    <td data-label="Updated"><div>{formatSimpleDate(new Date(ticket.updated.replace(/-/g, '\/').replace(/T.+/, '')))}</div></td>
                    <td data-label="Due"><div>{ticket.duedate !== null && ticket.duedate !== '' ? formatSimpleDate(new Date(ticket.duedate.replace(/-/g, '\/').replace(/T.+/, ''))) : ''}</div></td>
                    {/* <td>{ticket.fields.labels}</td> */}
                    {/* <td>{ticket.fields.priority.name}</td> */}
                    <td data-label="Status"><div>{ticket.status}</div></td>
                    <td><a href={"https://lahlouh.atlassian.net/browse/" + ticket.id} target="_blank">View Jira</a></td>
                </tr>
                )
            })
            }
            </tbody>
        </table>
        </>
        }

        {reviewTickets && reviewTickets.length > 0 &&
        <>
        <a name="pending-rev"></a>
        <div className="flex-wrapper">
            <div className="num-results"><strong>{reviewTickets && reviewTickets.length}</strong> result{reviewTickets && reviewTickets.length !== 1 && 's'}</div>
            <h2>Pending Review</h2>
            <div></div>
        </div>

        <table ref={reviewTable} className="review-table mb40" cellPadding="0" cellSpacing="0">
            <thead>
                <tr ref={stickyRow}>
                    <th onClick={() => sortReview('id')} title="Sort by Jira ID">JIRA <span ref={ref_review_id}></span></th>
                    <th onClick={() => sortReview('title')} title="Sort by Title">Title <span ref={ref_review_title}></span></th>
                    <th>Description</th>
                    <th onClick={() => sortReview('use')} title="Sort by Use">Use <span ref={ref_review_use}></span></th>
                    <th onClick={() => sortReview('updated')} title="Sort by Date Updated">Updated <span ref={ref_review_updated} ></span></th>
                    <th onClick={() => sortReview('duedate')} title="Sort by Due Dat">Due <span ref={ref_review_duedate} className="arrow-down"></span></th>
                    <th onClick={() => sortReview('status')} title="Sort by Status">Status <span ref={ref_review_status}></span></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

            <tr data-name="review" title="Click to expand results" onClick={expandResults}>
                <td><span className={search === '' ? "expand-down" : "expand-up"}></span></td>
                <td>View all {reviewTickets && reviewTickets.length} results</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            {reviewTickets && reviewTickets.map(ticket => {
                let str = ticket.description;
                
                if(str != null){
                    str = formatDescription(str);
                }else{
                    str = "";
                }

                return (
                
                <tr 
                    key={ticket.id}
                    className={search === '' ? "slave-highlight" : "slave-highlight active"} 
                    data-id={ticket.id}
                    title="Click to view Jira ticket"
                    onClick={() => rowClick(ticket.id)}
                >
                    <td data-label="Jira"><div>{ticket.id}</div></td>
                    <td data-label="Title"><div>{ticket.title.length < 101 ? ticket.title : ticket.title.substring(0,100) + "..."}</div></td>
                    <td data-label="Desc"><div>{str}</div></td>
                    <td data-label="Use"><div>{ticket.use ? ticket.use : ''}</div></td>
                    {/* <td>{ticket.fields.duedate}</td> */}
                    <td data-label="Updated"><div>{formatSimpleDate(new Date(ticket.updated.replace(/-/g, '\/').replace(/T.+/, '')))}</div></td>
                    <td data-label="Due"><div>{ticket.duedate !== null && ticket.duedate !== '' ? formatSimpleDate(new Date(ticket.duedate.replace(/-/g, '\/').replace(/T.+/, ''))) : ''}</div></td>
                    
                    
                    <td data-label="Status"><div>{ticket.status}</div></td>
                    <td><a href={"https://lahlouh.atlassian.net/browse/" + ticket.id} target="_blank">View Jira</a></td>
                </tr>
                )
            })
            }
            </tbody>
        </table>
        </>
        }

        {otherTickets && otherTickets.length > 0 &&
        <>
        <a name="other"></a>
        <div className="flex-wrapper">
            <div className="num-results"><strong>{otherTickets && otherTickets.length}</strong> result{otherTickets && otherTickets.length !== 1 && 's'}</div>
            <div>
                <h2>Other Development</h2>
                <div className="extra-info">Bug fixes / Enhancements to existing functionality</div>
            </div>
            <div></div>
        </div>

        <table ref={otherTable} className="other-table mb40" cellPadding="0" cellSpacing="0">
            <thead>
                <tr ref={stickyRow}>
                    <th onClick={() => sortOther('id')} title="Sort by Jira ID">JIRA <span ref={ref_other_id}></span></th>
                    <th onClick={() => sortOther('title')} title="Sort by Title">Title <span ref={ref_other_title}></span></th>
                    <th>Description</th>
                    <th onClick={() => sortOther('use')} title="Sort by Use">Use <span ref={ref_other_use}></span></th>
                    <th onClick={() => sortOther('updated')} title="Sort by Date Updated">Updated <span ref={ref_other_updated} ></span></th>
                    <th onClick={() => sortOther('duedate')} title="Sort by Due Dat">Due <span ref={ref_other_duedate} className="arrow-down"></span></th>
                    <th onClick={() => sortOther('status')} title="Sort by Status">Status <span ref={ref_other_status}></span></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

            <tr data-name="other" title="Click to expand results" onClick={expandResults}>
                <td><span className={search === '' ? "expand-down" : "expand-up"}></span></td>
                <td>View all {otherTickets && otherTickets.length} results</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            {otherTickets && otherTickets.map(ticket => {
                let str = ticket.description;
                
                if(str != null){
                    str = formatDescription(str);
                }else{
                    str = "";
                }

                return (
                <tr 
                    key={ticket.id}
                    className={search === '' ? "slave-highlight" : "slave-highlight active"} 
                    data-id={ticket.id}
                    title="Click to view Jira ticket"
                    onClick={() => rowClick(ticket.id)}
                >
                    <td data-label="Jira"><div>{ticket.id}</div></td>
                    <td data-label="Title"><div>{ticket.title.length < 101 ? ticket.title : ticket.title.substring(0,100) + "..."}</div></td>
                    <td data-label="Desc"><div>{str}</div></td>
                    <td data-label="Use"><div>{ticket.use ? ticket.use : ''}</div></td>
                    <td data-label="Updated"><div>{formatSimpleDate(new Date(ticket.updated.replace(/-/g, '\/').replace(/T.+/, '')))}</div></td>
                    <td data-label="Due"><div>{ticket.duedate !== null && ticket.duedate !== '' ? formatSimpleDate(new Date(ticket.duedate.replace(/-/g, '\/').replace(/T.+/, ''))) : ''}</div></td>
                    <td data-label="Status"><div>{ticket.status}</div></td>
                    <td><a href={"https://lahlouh.atlassian.net/browse/" + ticket.id} target="_blank">View Jira</a></td>
                </tr>
                )
            })
            }
            </tbody>
        </table>
        </>
        }

        {rejectedTickets && rejectedTickets.length > 0 &&
        <>
        <a name="rejected"></a>
        <div className="flex-wrapper">
            <div className="num-results"><strong>{rejectedTickets && rejectedTickets.length}</strong> result{rejectedTickets && rejectedTickets.length !== 1 && 's'}</div>
            <div>
                <h2>Cancelled / Rejected</h2>
            </div>
            <div></div>
        </div>

        <table ref={rejectedTable} className="tracking-table mb40" cellPadding="0" cellSpacing="0">
            <thead>
                <tr ref={stickyRow}>
                    <th onClick={() => sortRejected('id')} title="Sort by Jira ID">JIRA <span ref={ref_rejected_id}></span></th>
                    <th onClick={() => sortRejected('title')} title="Sort by Title">Title <span ref={ref_rejected_title}></span></th>
                    <th>Description</th>
                    <th onClick={() => sortRejected('use')} title="Sort by Use">Use <span ref={ref_rejected_use}></span></th>
                    <th onClick={() => sortRejected('updated')} title="Sort by Date Updated">Updated <span ref={ref_rejected_updated} className="arrow-down"></span></th>
                    <th onClick={() => sortRejected('status')} title="Sort by Status">Status <span ref={ref_rejected_status}></span></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

            <tr data-name="rejected" title="Click to expand results" onClick={expandResults}>
                <td><span className={search === '' ? "expand-down" : "expand-up"}></span></td>
                <td>View all {rejectedTickets && rejectedTickets.length} results</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>

            {rejectedTickets && rejectedTickets.map(ticket => {
                let str = ticket.description;
                
                if(str != null){
                    str = formatDescription(str)
                }else{
                    str = "";
                }

                return (
                
                <tr 
                    key={ticket.id}
                    className={search === '' ? "slave-highlight" : "slave-highlight active"} 
                    data-id={ticket.id}
                    title="Click to view Jira ticket"
                    onClick={() => rowClick(ticket.id)}
                >
                    <td data-label="Jira"><div>{ticket.id}</div></td>
                    <td data-label="Title"><div>{ticket.title.length < 101 ? ticket.title : ticket.title.substring(0,100) + "..."}</div></td>
                    <td data-label="Desc"><div>{str}</div></td>
                    <td data-label="Use"><div>{ticket.use ? ticket.use : ''}</div></td>
                    <td data-label="Updated"><div>{formatSimpleDate(new Date(ticket.updated.replace(/-/g, '\/').replace(/T.+/, '')))}</div></td>
                    <td data-label="Status"><div>{ticket.status}</div></td>
                    <td><a href={"https://lahlouh.atlassian.net/browse/" + ticket.id} target="_blank">View Jira</a></td>
                </tr>
                )
            })
            }
            </tbody>
        </table>
        </>
        }

        </div>
    </div>
    }
    {/* // End of DIV Main */}

    <div ref={refReturnTop} className="return-top" onClick={topFunction} title="Return to top">
            &#x25B2; Return to top
    </div>

    <div id="overlay" ref={overlay}></div>

    <div id="loading-overlay" ref={loadingOverlay}>
        <div className="deadCenter">
            <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>
    </>
  )
}
