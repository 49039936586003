import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const SignInButton = () => {
    const { instance } = useMsal();
  
    const handleLogin = (loginType) => {
      if (loginType === "popup") {
        instance.loginPopup(loginRequest).catch((e) => {
          console.log(e);
        });
      } else if (loginType === "redirect") {
        instance.loginRedirect(loginRequest).catch((e) => {
          console.log(e);
        });
      }
    };
    return (
      <button
        className="primary-button"
        onClick={() => handleLogin("redirect")}
      >
        Sign In
      </button>
      
    //   <DropdownButton
    //     variant="secondary"
    //     className="ml-auto"
    //     drop="start"
    //     title="Sign In"
    //   >
    //     <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>
    //       Sign in using Popup
    //     </Dropdown.Item>
    //     <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>
    //       Sign in using Redirect
    //     </Dropdown.Item>
    //   </DropdownButton>
    );
  };